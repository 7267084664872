@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.main {
  display: flex;
  height: 100vh;
}

.title {
  font-size: 3em;
}
.info {
  font-size: 1.1em;
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 1.5em;
  color: rgb(224, 224, 224);
}
.btn {
  margin: 0 auto;
  border: none;
  outline: none;
  padding: 0.75em 1em;
  font-size: 1em;
  letter-spacing: 1px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
  background-color: rgb(134, 49, 0);
  color: #fff;
}

.sidebar {
  min-width: 220px;
  flex-shrink: 0;
  background-color: rgba(22, 22, 22, 1);
  height: inherit;
  color: #fff;
}

.sidebar-item {
  padding: 0.75em 1em;
  display: block;
  transition: background-color 0.15s;
  border-radius: 5px;
}
.sidebar-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-title {
  display: flex;
  font-size: 1.2em;
  justify-content: space-between;
}
.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
}
.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
}
.sidebar-item.open > .sidebar-title .toggle-btn {
  transform: rotate(180deg);
}
.sidebar-content {
  padding-top: 0.25em;
  height: 0;
  overflow: hidden;
}
.sidebar-item.open > .sidebar-content {
  height: auto;
}

.sidebar-item.plain {
  color: #fff;
  text-decoration: none;
}
.sidebar-item.plain:hover {
  text-decoration: underline;
}
.sidebar-item.plain i {
  display: inline-block;
  width: 1.7em;
}
