fieldset {
  background-color: unset !important;
  border: 2px solid #cccaca !important;
  border-radius: 20px !important;
  padding: 20px !important;
  margin-bottom: 20px !important;
}

legend {
  border-bottom: unset !important;
  padding: 5px 10px !important;
  margin-bottom: 0 !important;
}

.event-image-preview .ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-image-preview .ant-image-preview-img-wrapper::before {
  display: none;
}
.mobile-only-sidedrawer .ant-drawer-content {
    background-color: #f6f2ef;
}

.mobile-only-sidedrawer .sidebar-item.plain {
  color: unset;
}